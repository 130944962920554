<template>
  <v-list-item

    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    active-class="link-active"
    @click="expandClick(item.identifier)"
  >
    <!-- :class="item.identifier" -->
    <!-- :active-class="`primary ${!isDark ? 'black' : 'white'}--text`" -->
    <template v-if="item.submenu">
      <v-list-item-icon
        v-if="text"
        class="v-list-item__icon--text"
        v-text="computedText"
      />

      <v-list-item-icon v-else-if="item.icon">
        <v-icon v-text="item.icon" />
      </v-list-item-icon>

      <v-list-item-content v-if="item.title || item.subtitle">
        <v-list-item-title
          class="ml-11 mt-1 py-1"
        >
          <v-icon
            x-small
            style="font-size: 8px;"
          >
            mdi-checkbox-blank-circle
          </v-icon>

          <span class="ml-2">{{ item.title }}</span>
          <v-badge
            v-if="notifications[item.notifkey] > 0 || notifications[item.notifkey] === '99+'"
            style="float: right; margin-right: 30px;"
            color="red"
            :content="notifications[item.notifkey]"
          />
        </v-list-item-title>

        <v-list-item-subtitle v-text="item.subtitle" />
      </v-list-item-content>
    </template>

    <template v-else>
      <v-list-item-icon
        v-if="text"
        class="v-list-item__icon--text"
        v-text="computedText"
      />

      <v-list-item-icon v-else-if="item.icon">
        <v-icon
          class="link-inactive"
          v-text="item.icon"
        />
      </v-list-item-icon>

      <v-list-item-content v-if="item.title || item.subtitle">
        <v-list-item-title
          class="link-inactive"
          v-text="item.title"
        />

        <v-list-item-subtitle v-text="item.subtitle" />
      </v-list-item-content>

      <v-list-item-icon v-if="item.expandable">
        <v-icon v-if="expandIcon">
          mdi-menu-up
        </v-icon>
        <v-icon v-else>
          mdi-menu-down
        </v-icon>
      </v-list-item-icon>
      <template v-else>
        <v-badge
          v-if="notifications[item.notifkey] > 0 || notifications[item.notifkey] === '99+'"
          style="float: right; margin-right: 23px; margin-top: 10px;"
          color="red"
          :content="notifications[item.notifkey]"
        />
      </template>
    </template>
  </v-list-item>
</template>

<script>
  import Themeable from 'vuetify/lib/mixins/themeable'
  import { mapState } from 'vuex'

  export default {
    name: 'Item',

    mixins: [Themeable],

    props: {
      item: {
        type: Object,
        default: () => ({
          href: undefined,
          icon: undefined,
          subtitle: undefined,
          title: undefined,
          to: undefined,
          submenu: undefined,
          expandable: undefined,
          identifier: undefined,
          hideDefault: undefined,
          notifkey: undefined,
        }),
      },
      text: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      expandIcon: true,
    }),

    computed: {
      ...mapState({
        notifications: state => state.drawer.menu_notif,
      }),

      computedText () {
        if (!this.item || !this.item.title) return ''

        let text = ''

        this.item.title.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })

        return text
      },

      href () {
        return this.item.href || (!this.item.to ? '#' : undefined)
      },
    },

    mounted () {
      if (this.item.expandable) {
        if (this.notifications.sub_menu_asset_verification > 0 || this.notifications.sub_menu_asset_verification === '99+') this.expandClick('asset')
      }

      // console.log(this.item)
    },

    methods: {
      async expandClick (id) {
        if (!id) return
        id = id + '-child'
        this.expandIcon = !this.expandIcon
        const el = document.getElementsByClassName(id)
        if (!el) return
        for (let i = 0; i < el.length; i++) {
          if (el[i].style.display === 'none') {
            el[i].style.display = 'block'
          } else {
            el[i].style.display = 'none'
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .link-active {
    background: #FFFFFF;
    color: $green-2;
  }

  .link-inactive {
    color: $gray-50;
  }
</style>

import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'Login',
      path: '/Login',
      component: () => import('@/views/dashboard/Login'),
    },

    {
      name: 'redirectWA',
      path: '/wa-login/:id?',
      component: () => import('@/views/dashboard/pages/others/ForwarderPage.vue'),
    },

    // Register
    {
      name: 'Register',
      path: '/register',
      component: () => import('@/views/dashboard/pages/registrasi/Registrasi.vue'),
    },

    // Register Universitas
    {
      name: 'RegisterUniversitas',
      path: '/register/universitas',
      component: () => import('@/views/dashboard/pages/registrasi/universitas/RegistrasiUniversitas.vue'),
    },

    // Register Profesional
    {
      name: 'RegisterProfesionalDataDiri',
      path: '/register/profesional',
      component: () => import('@/views/dashboard/pages/registrasi/profesional/RegistrasiProfesionalDataDiri.vue'),
    },
    {
      name: 'RegisterProfesionalPerusahaan',
      path: '/register/profesional/perusahaan',
      component: () => import('@/views/dashboard/pages/registrasi/profesional/RegistrasiProfesionalPerusahaan.vue'),
    },

    // Password
    {
      name: 'Password',
      path: '/password',
      component: () => import('@/views/dashboard/pages/registrasi/Password.vue'),
    },

    // Pembayaran
    {
      name: 'Pembayaran',
      path: '/payment',
      component: () => import('@/views/dashboard/pages/payment/Pembayaran.vue'),
    },
    {
      name: 'PembayaranDetail',
      path: '/payment/detail',
      component: () => import('@/views/dashboard/pages/payment/PembayaranDetail.vue'),
    },

    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },

        // Keanggotaan
        {
          name: 'Keanggotaan',
          path: 'keanggotaan',
          component: () => import('@/views/dashboard/pages/keanggotaan/Keanggotaan.vue'),
        },
        {
          name: 'KeanggotaanDetail',
          path: 'keanggotaan/detail/:verification?/:type?/:id?',
          component: () => import('@/views/dashboard/pages/keanggotaan/KeanggotaanDetail.vue'),
        },

        // Perusahaan
        {
          name: 'Perusahaan',
          path: 'perusahaan',
          component: () => import('@/views/dashboard/pages/perusahaan/Perusahaan.vue'),
        },

        // Universitas
        {
          name: 'Universitas',
          path: 'universitas',
          component: () => import('@/views/dashboard/pages/universitas/Universitas.vue'),
        },

        // Program Kerja
        {
          name: 'ProgramKerja',
          path: 'programkerja',
          component: () => import('@/views/dashboard/pages/programkerja/ProgramKerja.vue'),
        },

        // Struktur Organisasi
        {
          name: 'StrukturOrganisasi',
          path: 'struktur',
          component: () => import('@/views/dashboard/pages/strukturorganisasi/StrukturOrganisasi.vue'),
        },
        {
          name: 'StrukturBpp',
          path: 'struktur/detail/bpp/:id?',
          component: () => import('@/views/dashboard/pages/strukturorganisasi/StrukturOrganisasiDetail.vue'),
        },
        {
          name: 'StrukturBpd',
          path: 'struktur/detail/bpd/:index?/:id?',
          component: () => import('@/views/dashboard/pages/strukturorganisasi/StrukturOrganisasiDetail.vue'),
        },
        {
          name: 'StrukturBpc',
          path: 'struktur/detail/bpc/:index?/:id?',
          component: () => import('@/views/dashboard/pages/strukturorganisasi/StrukturOrganisasiDetail.vue'),
        },

        // Event
        {
          name: 'Event',
          path: 'event',
          component: () => import('@/views/dashboard/pages/event/Event.vue'),
        },

        // Galeri
        {
          name: 'Galeri',
          path: 'galeri',
          component: () => import('@/views/dashboard/pages/galeri/Galeri.vue'),
        },

        // Format Surat
        {
          name: 'FormatSurat',
          path: 'formatsurat',
          component: () => import('@/views/dashboard/pages/formatsurat/FormatSurat.vue'),
        },

        // Legalitas
        {
          name: 'Legalitas',
          path: 'legalitas',
          component: () => import('@/views/dashboard/pages/legalitas/Legalitas.vue'),
        },

        // Artikel
        {
          name: 'Artikel',
          path: 'artikel',
          component: () => import('@/views/dashboard/pages/artikel/Artikel.vue'),
        },
        {
          name: 'ArtikelTambah',
          path: 'artikel/tambah',
          component: () => import('@/views/dashboard/pages/artikel/ArtikelTambah.vue'),
        },
        {
          name: 'ArtikelEdit',
          path: 'artikel/edit/:id?',
          component: () => import('@/views/dashboard/pages/artikel/ArtikelEdit.vue'),
        },
        {
          name: 'ArtikelDetail',
          path: 'artikel/detail/:id?',
          component: () => import('@/views/dashboard/pages/artikel/ArtikelDetail.vue'),
        },

        // Tiket
        {
          name: 'Tiket',
          path: 'tiket',
          component: () => import('@/views/dashboard/pages/tiket/Tiket.vue'),
        },

        // Pengaturan
        {
          name: 'Pengaturan',
          path: 'pengaturan',
          component: () => import('@/views/dashboard/pages/pengaturan/Pengaturan.vue'),
        },
        {
          name: 'PengaturanPerusahaan',
          path: 'pengaturan/perusahaan',
          component: () => import('@/views/dashboard/pages/pengaturan/Perusahaan.vue'),
        },
        {
          name: 'PengaturanPerusahaanTambah',
          path: 'pengaturan/perusahaan/tambah',
          component: () => import('@/views/dashboard/pages/pengaturan/PerusahaanTambah.vue'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Register' || to.name === 'RegisterUniversitas' || to.name === 'redirectWA' ||
  to.name === 'RegisterProfesionalDataDiri' || to.name === 'RegisterProfesionalPerusahaan' ||
  to.name === 'Password' || to.name === 'Pembayaran' || to.name === 'PembayaranDetail') {
    next()
    return
  }

  if (to.name !== 'Login') {
    if (localStorage.getItem('token') === null) {
      next({ name: 'Login' })
      return
    }

    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
    axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')
    axios.post('/v1/check-auth').then((res) => {
      if (res.data.status === 200) {
        next()
      }
    }).catch((e) => {
      localStorage.clear()
      next({ name: 'Login' })
    })
  } else {
    next()
  }
})

export default router

const state = {
    loginInfo: null,
}

const mutations = {
    SET_LOGIN_INFO (state, payload) {
        state.loginInfo = payload
    },
}

const getters = {
    getLoginInfo: (state) => {
        return state.loginInfo
    },
}

const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
